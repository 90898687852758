<template>
    <div class="home-page_wrapper">
        <Slider />
        <h1 class="scholarships-header">{{ $t('homeTitle') }}</h1>
        <div class="scholarships-types">
            <div class="scholarships-types_item">
              <router-link to="/scholarships/Metchnikov">
                <img src="@/assets/images/Metchnikov1.png" />
              </router-link>
              <router-link to="/scholarships/Metchnikov">
                <p>{{ $t('scholarshipMetchnikov') }}</p>
              </router-link>
                <div class="apply_wrap">
                    <router-link to="/scholarships/Metchnikov" class="apply">
                        {{ $t('more') }}
                    </router-link>
                </div>
            </div>
            <div class="scholarships-types_item">
              <router-link class="img-disabled" to="/scholarships/Vernadski">
                <img src="@/assets/images/Vernadski1.png" />
              </router-link>
              <router-link class="text-disabled" to="/scholarships/Vernadski">
                <p>{{ $t('scholarshipVernadski') }}</p>
              </router-link>
                <div class="apply_wrap">
                    <router-link to="/scholarships/Vernadski" class="apply apply-disabled">
                        {{ $t('more') }}
                    </router-link>
                </div>
            </div>
            <div class="scholarships-types_item">
              <router-link to="/scholarships/Ostrogradski">
                <img src="@/assets/images/Ostrogradski.png" />
              </router-link>

              <router-link to="/scholarships/Ostrogradski">
                <p class="ostrogradski-text">{{ $t('scholarshipOstrogradski') }}</p>
              </router-link>
                <div class="apply_wrap">
                    <router-link to="/scholarships/Ostrogradski" class="apply">
                        {{ $t('more') }}
                    </router-link>
                </div>
            </div>
            <div class="scholarships-types_item">
                <div class="master-img">
                  <router-link to="/scholarships/Master">
                    <img src="@/assets/images/master_logo.png" />
                  </router-link>
                </div>
              <router-link to="/scholarships/Master">
                <p class="master-text">{{ $t('scholarshipMaster') }}</p>
              </router-link>
                <div class="apply_wrap">
                    <router-link to="/scholarships/Master" class="apply">
                        {{ $t('more') }}
                    </router-link>
                </div>
            </div>
<!--            <div class="scholarships-types_item">-->
<!--                <div class="cuf-img">-->
<!--                    <router-link to="/scholarships/CUF">-->
<!--                        <img src="@/assets/images/CUF.png" />-->
<!--                    </router-link>-->
<!--                </div>-->
<!--                <router-link to="/scholarships/CUF">-->
<!--                    <p>{{ $t('scholarshipCUF') }}</p>-->
<!--                </router-link>-->
<!--                <div class="apply_wrap">-->
<!--                    <router-link to="/scholarships/CUF" class="apply">-->
<!--                        {{ $t('more') }}-->
<!--                    </router-link>-->
<!--                </div>-->
<!--            </div>-->
            <div class="scholarships-types_item empty-item"></div>
        </div>
        <h1 class="scholarships-header">{{ $t('partnerScholarshipsTitle') }}</h1>
        <div class="partner-scholarships_wrap">
            <div class="scholarships-types-sec_item partner-item">
              <div class="scholarships-types-sec_item">
                <div class="master-img">
                  <router-link class="img-disabled" to="/scholarships/audencia">
                    <img src="@/assets/images/audencia.png" />
                  </router-link>
                </div>
                <router-link class="text-disabled" to="/scholarships/audencia">
                  <p class="edhec-text">{{ $t('scholarshipAudencia') }}</p>
                </router-link>
                <div class="apply_wrap">
                    <router-link to="/scholarships/audencia" class="apply apply-disabled">
                        {{ $t('more') }}
                    </router-link>
                </div>
              </div>
            </div>
            <div class="scholarships-types_item partner-item">
                <div class="edhec-img">
                    <router-link class="img-disabled" to="/scholarships/Edhec">
                        <img src="@/assets/images/edhec.png" />
                    </router-link>
                </div>
                <router-link class="text-disabled" to="/scholarships/Edhec">
                    <p class="edhec-text">{{ $t('scholarshipEdhec') }}</p>
                </router-link>
                <div class="apply_wrap">
                    <router-link to="/scholarships/Edhec" class="apply apply-disabled">
                        {{ $t('more') }}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="scholarships-types questions_wrap">
            <div class="questions">
                <h1 class="scholarships-header questions-text">{{ $t('questions') }}</h1>
                <button class="questions-btn" @click="toFaqPage">{{ $t('more') }}</button>
            </div>
            <div class="icons-wrap">
                <img src="@/assets/images/master_logo.png" />
                <img src="@/assets/images/partner2.png" />
                <img class="icon3" src="@/assets/images/partner4.png" />
                <img class="icon4" src="@/assets/images/partner3.png" />
            </div>
        </div>
        <!--<h1 class="scholarships-header">{{ $t('blog') }}</h1>
        <div class="scholarships-types blog-wrap">
            <div
                v-for="(item, i) in blogData"
                :key="i"
                class="blog-item"
            >
                <div v-if="$i18n.locale === 'ru'">
                    <p class="title" v-html="item.title"></p>
                    <p class="description" v-html="item.description"></p>
                    <p class="date">{{ $moment(item.publicationdate).format('DD/MM/YYYY')}}</p>
                    <a
                        :href="item.link"
                        class="blog-btn_wrap"
                    >
                      <img src="@/assets/images/arrow-right_blue.svg" />
                      <span class="blog-btn">{{ $t('more') }}</span>
                    </a>
                </div>
              <div v-else>
                  <p class="title" v-html="item.titleru"></p>
                  <p class="description" v-html="item.descriptionru"></p>
                  <p class="date">{{ $moment(item.publicationdateru).format('DD/MM/YYYY')}}</p>
                  <a
                      :href="item.link"
                      class="blog-btn_wrap"
                  >
                    <img src="@/assets/images/arrow-right_blue.svg" />
                    <span class="blog-btn">{{ $t('more') }}</span>
                  </a>
              </div>

            </div>
        </div>-->
        <questions-modal />
        <has-account
            v-if="currentScholarshipTypeId"
            :id="currentScholarshipTypeId"
            @close="currentScholarshipTypeId = ''"
        />
    </div>
</template>

<script>
import Slider from "../components/Slider";
import QuestionsModal from "../components/questions-modal";
import HasAccount from "../components/has-account";
import {mapActions, mapGetters} from "vuex";
export default {

    data: () => ({
        blogData: null,
        currentDay: '',
        currentScholarshipTypeId: ''
    }),

    components: {
        Slider,
        QuestionsModal,
        HasAccount,
    },
    computed: {
        ...mapGetters('applications', ['formattedScholarships']),

    },
    async mounted() {
//        this.currentDay = this.$moment(new Date()).format('YYYY-MM-DD')
//        if(!this.formattedScholarships) {
//            await this.getAllScholarships()
//        }
//        await this.$axios.get(`${process.env.VUE_APP_API_URL}/bgfContentBlocks/`)
//              .then(res => this.blogData = res.data)
//              .catch(err => console.log(err))
    },
    methods: {
        ...mapActions('applications',['getAllScholarships']),

        changeCurrentScholarshipId(id) {
          console.log(id,"id")
            this.currentScholarshipTypeId = id
            localStorage.setItem('currentScholarshipTypeId', id)
        },
        toFaqPage() {
            this.$router.push('/faq')
        },
        isDisabled(scholarshipTypeId) {
            let disabled = false
            if(this.formattedScholarships) {
                const currentTypeScholarships = this.formattedScholarships[scholarshipTypeId]
                if(!currentTypeScholarships) {
                    disabled = true
                } else {
                    let hasOpenedScholarship = false
                    currentTypeScholarships.forEach(el => {
                        const closingDate = this.$moment(el.closingDate).format('YYYY-MM-DD')
                        if(this.$moment(this.currentDay).isBefore(closingDate)) {
                            hasOpenedScholarship = true
                        }
                    })
                    if(!hasOpenedScholarship) {
                        disabled = true
                    }
                }
            }
            return disabled
        },
        toBlogPage(i) {
            this.$router.push({
                name: 'blog',
                query: {
                    q: i+1
                }
            })
        }
    }

}

</script>

<style lang="scss" scoped>
.md-theme-default a:not(.md-button) {
  color: transparent;
}
.home-page_wrapper {

    .scholarships-header {
        width: 95%;
        margin: 90px auto 35px;
        font-weight: 900;
        font-size: 46px;
        line-height: 64px;
        text-align: center;

        @media(max-width: 1024px) {
            font-weight: bold;
            font-size: 30px;
            line-height: 43px;
            margin: 40px auto 30px;
        }
    }

    .scholarships-types {
        display: flex;
        width: 90%;
        margin: 0 auto 85px;
        flex-wrap: wrap;
        justify-content: center;

        @media(max-width: 1280px) {
            width: 100%;
        }
        @media(max-width: 1024px) {
            margin-bottom: 40px;
        }

        &_item {
            width: 47%;
            background: #F5F8FD;
            border-radius: 6px;
            margin: 15px;
            padding-bottom: 65px;
            max-width: 550px;

            @media(max-width: 1280px) {
                max-width: 420px;
            }
            @media(max-width: 1024px) {
                padding-bottom: 40px;
            }
            @media(max-width: 768px) {
                max-width: 335px;
                margin: 10px;
            }
            @media(max-width: 660px) {
                max-width: 87%;
                width: 87%;
            }

            img {
                width: 100%;
                border-radius: 6px 6px 0 0;
            }

            .master-img {
                width: 95%;
                margin: 25px auto;
                height: auto;

                @media(max-width: 1280px) {
                    margin: 68px auto;
                }

                @media(max-width: 768px) {
                    margin: 57px auto;
                }
            }

            .cuf-img {
                width: 90%;
                margin: 20px auto;
            }

            p {
                font-weight: bold;
                font-size: 22px;
                line-height: 34px;
                text-align: center;
                margin: 30px auto;
                width: 85%;
                color: black;

                @media(max-width: 1280px) {
                    width: 96%;
                }

                @media(max-width: 1024px) {
                    font-size: 18px;
                    line-height: 32px;
                    margin-bottom: 20px;
                }

                @media(max-width: 768px) {
                    width: 77%;
                }
            }

            .ostrogradski-text {
                min-height: 61px;
            }

            .master-text {
                @media(max-width: 768px) {
                    width: 90%;
                }
            }

            .apply {
                width: 210px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #0045FF;
                color: #FFFFFF;
                text-decoration: none;
                text-align: center;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                border-radius: 6px;
                cursor: pointer;

                &_wrap {
                    display: flex;
                    justify-content: center;
                }

                &-disabled {
                    background-color: #abadb1;
                    pointer-events: none;
                }
            }
        }
    }

    .scholarships-types-sec {
    display: flex;
    width: 90%;
    margin: 0 auto 85px;
    flex-wrap: wrap;
    justify-content: center;

    @media(max-width: 1280px) {
      width: 100%;
    }
    @media(max-width: 1024px) {
      margin-bottom: 40px;
    }

    &_item {
      background: #F5F8FD;
      border-radius: 6px;
      margin: 15px;
      padding-bottom: 65px;
      max-width: 550px;

      @media(max-width: 1280px) {
        max-width: 420px;
      }
      @media(max-width: 1024px) {
        padding-bottom: 40px;
      }
      @media(max-width: 768px) {
        max-width: 335px;
        margin: 10px;
      }
      @media(max-width: 660px) {
        max-width: 87%;
        width: 87%;
      }

      img {
        width: 100%;
        border-radius: 6px 6px 0 0;
      }

      .master-img {
        width: 90%;
        margin: 30px auto 65px;

        @media(max-width: 1280px) {
          margin: 68px auto;
        }

        @media(max-width: 768px) {
          margin: 57px auto;
        }
      }

      .cuf-img {
        width: 90%;
        margin: 20px auto;
      }

      p {
        font-weight: bold;
        font-size: 22px;
        line-height: 34px;
        text-align: center;
        margin: 30px auto;
        width: 85%;
        color: black;

        @media(max-width: 1280px) {
          width: 96%;
        }

        @media(max-width: 1024px) {
          font-size: 18px;
          line-height: 32px;
          margin-bottom: 20px;
        }

        @media(max-width: 768px) {
          width: 77%;
        }
      }

      .ostrogradski-text {
        min-height: 61px;
      }

      .master-text {
        @media(max-width: 768px) {
          width: 90%;
        }
      }

      .apply {
        width: 210px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0045FF;
        color: #FFFFFF;
        text-decoration: none;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        border-radius: 6px;
        cursor: pointer;

        &_wrap {
          display: flex;
          justify-content: center;
        }

        &-disabled {
          background-color: #abadb1;
          pointer-events: none;
        }
      }
    }
  }

    .empty-item {
        background-color: transparent;
    }

    .partner-item {
        width: 40%;
        //margin: 0 auto 85px;

        @media(max-width: 768px) {
            width: auto;
        }

        @media(max-width: 660px) {
            max-width: 87%;
            width: 87%;
        }
    }


    .partner-scholarships_wrap {
        display: flex;
        justify-content: center;
        margin-bottom: 85px;

        @media(max-width: 660px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .edhec-img {
        width: 90%;
        margin: 30px auto 50px;
        img {
            border-radius: 0;
        }

        @media(max-width: 1280px) {
            margin-bottom: 40px;
        }

        @media(max-width: 900px) {
            margin-bottom: 30px;
        }
    }

    .edhec-text {
        /*text-transform: uppercase;*/
        min-height: 68px;

        @media(max-width: 768px) {
            width: 90% !important;
        }
    }

    .questions {
        background: url("../assets/images/pens.png") no-repeat;
        background-position: bottom;
        width: 100%;
        height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 100px;

        @media(max-width: 420px) {
            background-image: url("../assets/images/pens-mobile.png");
        }

        &_wrap {
            flex-direction: column;
        }

        &-text {
            width: 83%;
            margin: 0 auto 60px;
            color: #FFFFFF;
            @media(max-width: 420px) {
                width: 70%;
            }
        }

        &-btn {
            width: 196px;
            height: 60px;
            display: block;
            margin: 0 auto;
            background: #FFFFFF;
            border: 1px solid #CDD0D9;
            font-weight: bold;
            font-size: 20px;
            line-height: 34px;
            border-radius: 6px;
            outline: none;
            cursor: pointer;

            @media(max-width: 1024px) {
                width: 156px;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    .icons-wrap {
        width: 80%;
        background: #FFFFFF;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media(max-width: 768px) {
            width: 96%;
        }

        @media(max-width: 600px) {
            flex-wrap: wrap;
            justify-content: space-around;
        }

        @media(max-width: 420px) {
            flex-direction: column;
        }

        .icon3 {
           width: 100px;
        }

        .icon4 {
           width: 100px;
        }

        img {
            width: 160px;

            @media(max-width: 1024px) {
                margin: 10px;
            }

        }
    }

    .blog-wrap {
        @media(max-width: 1440px) {
            width: 90%;
        }
        @media(max-width: 1280px) {
            width: 100%;
        }
        @media(max-width: 1024px) {
            justify-content: flex-start;
            max-width: 850px;
            margin: 0 auto;
        }

        @media(max-width: 900px) {
            width: 90%;
            max-width: 690px;
        }
        @media(max-width: 767px) {
            flex-direction: column;
        }
        @media(max-width: 767px) {
            justify-content: center;
            align-items: center;
        }
    }

    .blog-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30%;
        height: 427px;
        margin-right: 30px;
        padding: 30px;
        background: #F5F8FD;

        @media(max-width: 1150px) {
          height: 445px;
        }

        @media(max-width: 1024px) {
            width: 50%;
            max-width: 415px;
            height: 340px;
            margin: 0;
        }

        @media(max-width: 900px) {
            max-width: 335px;
            height: 400px;
        }

        @media(max-width: 767px) {
            width: 95%;
            max-width: 95%;
            margin-bottom: 25px;
            height: auto;
        }

        &:first-child {
            @media(max-width: 1024px) {
                margin-right: 20px;
                margin-bottom: 20px;
            }
            @media(max-width: 660px) {
                margin-right: 0;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        .date {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            margin-top: 12px;
        }

        .title {
            min-height: 120px;
            font-weight: bold;
            font-size: 22px;
            line-height: 34px;
            margin-bottom: 12px;
            word-break: break-word;

            @media(max-width: 1024px) {
                min-height: 40px;
            }
        }

        .description {
            font-weight: normal;
            font-size: 18px;
            line-height: 32px;
            min-height: 96px;
            @media(max-width: 1280px) {
                min-height: 128px;
            }
            @media(max-width: 1024px) {
                min-height: 96px;
            }
        }

        .blog-btn {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            margin-left: 20px;
            color: #202021;
        }

        .blog-btn_wrap{
          margin-top: 50px;
          display: flex;
          align-items: center;
          cursor: pointer;
          @media(max-width: 767px) {
            margin-top: 40px;
          }
        }
    }

}
.apply_Vernadski {
  pointer-events: none;
  background-color: #abadb1 !important;
}
.text-disabled {
  pointer-events: none;
}
.text-disabled p {
  color: #abadb1 !important;
}
.img-disabled {
  pointer-events: none;
}
.img-disabled img {
  opacity: 0.5;
}
.scholarships-types-sec_item .master-img a {
  pointer-events: none;
}
</style>