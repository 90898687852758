<template>
    <div class="question-modal-wrapper">
        <div class="question-mark" @click="showModal = true">
           <img src="@/assets/images/question-mark.svg" alt="question">
        </div>
        <div class="modal-wrapper" v-if="showModal">
            <span @click="showModal = false">
                <img src="@/assets/images/close-icon.svg" alt="close">
            </span>
            <p class="question">{{ $t('questionsPopup') }}</p>
            <router-link to="/faq">
                <button class="question-btn">{{ $t('more') }}</button>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'question-modal',
        data: () => ({
            showModal: false,
        }),
    }
</script>

<style lang="scss" scoped>
    a:not(.md-button):hover {
        text-decoration: none;
    }
    .question-modal-wrapper{
        .question-mark {
            width: 64px;
            height: 64px;
            background: #0045FF;
            border-radius: 50%;
            position: fixed;
            bottom: 40px;
            right: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .modal-wrapper {
            width: 500px;
            background: #FFFFFF;
            box-shadow: 3px 2px 15px -8px black;
            position: fixed;
            bottom: 40px;
            right: 40px;
            z-index: 9999;

            @media (max-width: 570px) {
                right: 0;
                width: 100%;
            }

            span {
                display: flex;
                justify-content: flex-end;
                padding: 24px 29px 0;
                cursor: pointer;
            }

            .question {
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                padding: 14px 40px 0 40px;
            }

            .question-btn {
                width: 173px;
                margin: 32px auto;
                padding: 12px 32px;
                display: block;
                background: #0045FF;
                color: white;
                border-radius: 6px;
                border: none;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                cursor: pointer;
            }
        }
    }
</style>