<template>
    <div class="slick-slider">
      <div class="slick-wrapper">
        <div class="slick-item item1">
          <div class="item-content">
            <h1 class="item-head">{{ $t('sliderTitle') }}</h1>
<!--            <router-link to="/blog?q=3">-->
<!--              <button class="item-btn">{{ $t('more') }}</button>-->
<!--            </router-link>-->
              <router-link to="/scholarships/Master">
                <button class="item-btn">{{ $t('more') }}</button>
              </router-link>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'slider',

    data: () => ({
        width: '700px'
    }),

    mounted() {
        this.resizeHandler()
        window.addEventListener('resize', this.resizeHandler)
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.resizeHandler)
    },

    methods: {
        resizeHandler() {
            const w = window.outerWidth
          // if(w <= 768) {
          //   this.width = w + 'px'
          // } else
           if (w <= 900) {
              this.width = w + 'px'
            } else if(w <= 1280) {
                this.width = '900px'
            } else if(w <= 1400) {
                this.width = '1000px'
            } else if(w <= 1500) {
                this.width = '1270px'
            } else {
                this.width = '1440px'
            }
        },
    }

}

</script>

<style lang="scss" scoped>
.slick-slider {
    .slick-wrapper {
        width: 100%;
        max-width: 1440px;
        height: 562px;
        border: 1px solid grey;
        border-radius: 6px;
        margin: 0 auto;

        @media (max-width: 1500px) {
            max-width: 1270px;
        }
        @media (max-width: 1400px) {
            max-width: 1000px;
        }
        @media (max-width: 1280px) {
            max-width: 900px;
        }
        @media (max-width: 1024px) {
            height: 482px;
        }
        @media (max-width: 980px) {
            max-width: 700px;
        }
        @media (max-width: 768px) {
            border: none;
            border-radius: 0;
        }
    }
    .slick-item {
        position: relative;
        width: 100%;
        height: 562px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 1024px) {
            height: 482px;
        }
        @media (max-width: 768px) {
            border-radius: 0;
        }
    }
    .item1 {
        background: url("../assets/images/slider1.png") no-repeat;
        @media(max-width: 768px) {
            background-size: cover;
            background-position: center;
        }
    }
    .item2 {
        background: url("../assets/images/slider1.png") no-repeat;
        @media(max-width: 768px) {
            background-size: cover;
            background-position: center;
        }
    }
    .item3 {
        background: url("../assets/images/slider1.png") no-repeat;
        @media(max-width: 768px) {
            background-size: cover;
            background-position: center;
        }
    }
    .item-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: 1500px) {
            width: 53%;
        }
        @media (max-width: 1380px) {
            width: 60%;
        }
        @media (max-width: 1280px) {
            width: 72%;
        }
        @media (max-width: 1024px) {
            width: 46%;
        }
        @media (max-width: 768px) {
            width: 54%;
        }
        @media (max-width: 420px) {
            width: 75%;
        }
    }
    .item-head {
        font-weight: 900;
        font-size: 36px;
        line-height: 52px;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 60px;
        @media (max-width: 1024px) {
            font-weight: bold;
            font-size: 22px;
            line-height: 34px;
        }
    }
    .item-btn {
        width: 196px;
        height: 60px;
        display: block;
        margin: 0 auto;
        background: #FFFFFF;
        border: 1px solid #CDD0D9;
        font-weight: bold;
        font-size: 20px;
        line-height: 34px;
        border-radius: 6px;
        outline: none;
        cursor: pointer;

        @media (max-width: 1024px) {
            width: 156px;
            height: 48px;
            font-size: 16px;
            line-height: 24px;
        }
    }
}
</style>

<style lang="scss">

.slick-slider .slick-next:before {
    content: "";
    display: block;
    background: url("../assets/images/slider-next.svg") no-repeat;
    width: 48px;
    height: 48px;
    @media (max-width: 1024px) {
        display: none;
    }
}
.slick-slider .slick-next {
    width: 48px;
    height: 48px;
    right: 150px;
    top: 49%;
    z-index: 9;
    @media (max-width: 1500px) {
        right: 100px;
    }
    @media (max-width: 1280px) {
        right: 50px;
    }
    @media (max-width: 1024px) {
        display: none;
    }
}
.slick-slider .slick-prev {
    width: 48px;
    height: 48px;
    left: 150px;
    top: 49%;
    z-index: 9;
    @media (max-width: 1500px) {
        left: 100px;
    }
    @media (max-width: 1280px) {
        left: 50px;
    }
    @media (max-width: 1024px) {
        display: none;
    }
}
.slick-slider .slick-prev:before {
    content: "";
    display: block;
    background: url("../assets/images/slider-prev.svg") no-repeat;
    width: 48px;
    height: 48px;
    @media (max-width: 1024px) {
        display: none;
    }
}
.slick-slider .slick-dots {
    bottom: 45px;
    @media (max-width: 1024px) {
        bottom: 37px;
    }
}
.slick-slider .slick-dots li.slick-active button:before {
    color: #FFFFFF;
}

</style>